import axiosPlugin from '../../plugins/axios';
import toastPlugin from '../../plugins/messages';
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const cancelAnalysis = async ( campaignAnalysisId ) => {
    try {
        return (await axios.delete(`/campaign-analysis/${campaignAnalysisId}`, {
            showLoader: false,
        })).data;
    } catch (error) {
        toast(
            'error',
            `Impossible d'annuler la commande (Message: ${getMessage(error.response.status)})`,
        );

        throw error;
    }
};

const getAnalysesForCampaign = async (campaignId) => {
    try {
        const response = await axios.get(`/campaigns/${campaignId}/analyses`);
        return response.data;
    } catch (error) {
        toast(
            'error',
            `Impossible de récupèrer les analyses de la campagne (${getMessage(error.response.status)})`,
        );

        throw error;
    }
};

export default {
    getAnalysesForCampaign,
    cancelAnalysis,
};
