import axiosPlugin from '../../plugins/axios';
import toastPlugin from '../../plugins/messages';
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

/**
 * Upload picture to S3 and save object to DB, s3Path will be automatically overwritten
 * @param {FormData} formData files informations and payload
 * @param {object} document infos to store about the document entity
 */
const upload = async(file, info) => {
    try {
        const formData = new FormData();
        formData.append('coreId', info.coreId);
        formData.append('file', file);
        let response = await axios.post('/core-pictures/upload', formData);

        info.path = response.data.path;
        let result;
        if(info.id) result = await update(info.id, {...info});
        else result = await create({...info});

        toast('success', 'Document ajouté !');
        return result;
    } 
    catch (error){
        toast(
            'error',
            'Impossible d\'uploader la photo' + getMessage(error.response.status)
        );
        throw error;
    }
};

const create = async corePicData => {
    try {
        const response = await axios.post('/core-pictures', corePicData);
        return response.data;
    }
    catch (error){
        toast(
            'error',
            'Impossible de créer la photo' + getMessage(error.response.status)
        );
        throw error;
    }
};

const update = async(corePictureId, corePicData) => {
    try {
        const response = await axios.put(
            `/core-pictures/${corePictureId}`,
            corePicData
        );
        return response.data;
    }
    catch (error){
        toast(
            'error',
            'Impossible de modifier la photo' + getMessage(error.response.status)
        );
        throw error;
    }
};

const modify = async(corePictureId, corePicData) => {
    try {
        const response = await axios.patch(
            `/core-pictures/${corePictureId}`,
            corePicData
        );
        return response.data;
    }
    catch (error){
        toast(
            'error',
            'Impossible de modifier la photo' + getMessage(error.response.status)
        );
        throw error;
    }
};

const remove = async corePictureId => {
    try {
        const response = await axios.delete(`/core-pictures/${corePictureId}`);
        return response.data;
    }
    catch (error){
        toast(
            'error',
            'Impossible de supprimer la photo' + getMessage(error.response.status)
        );
        throw error;
    }
};

const get = async corePictureId => {
    try {
        const response = await axios.get(
            `/core-pictures/${corePictureId}`,
            {
                showLoader: false
            }
        );
        return response.data;
    }
    catch (error){
        toast(
            'error',
            'Impossible de récupérer les informations la photo' + getMessage(error.response.status)
        );
        throw error;
    }
};

const downloadAllPictures = async coreId => {
    try {
        const response = await axios.get('cores/' + coreId + '/pictures', {
            responseType: 'blob'
        });
        return response;
    }
    catch (error){
        toast(
            'error',
            'Impossible de télécharger le document' +
        getMessage(error.response.status)
        );
        throw error;
    }
};

const downloadPictureByPath = async corePicturePath => {
    try {
        const response = await axios.get('presigned/' + corePicturePath);
        return response.data;
    }
    catch (error){
        toast(
            'error',
            'Impossible de télécharger le document' +
        getMessage(error.response.status)
        );
        throw error;
    }
};

const downloadPictureById = async corePictureId => {
    try {
        const response = await axios.get('core-pictures/' + corePictureId + '/download', {responseType: 'blob'});
        return response.data;
    }
    catch (error){
        toast(
            'error',
            'Impossible de télécharger le document' +
        getMessage(error.response.status)
        );
        throw error;
    }
};

const checkCorePictures = async coreId => {
    return new Promise(async(resolve, reject) => {
        try {
            const response = await axios.get(
                `/cores/${coreId}/core-pictures/check`,
                {
                    showLoader: false
                }
            );

            resolve(response.data);
        }
        catch (error){
            toast(
                'error',
                `Impossible de vérifier les images de la carotte #${coreId} (message: ${getMessage(error.response.status)})`
            );

            reject(error);
        }
    });
};

export default {
    upload,
    create,
    update,
    modify,
    get,
    remove,
    downloadAllPictures,
    downloadPictureByPath,
    downloadPictureById,
    checkCorePictures,
};
