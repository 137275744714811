import Vue from 'vue';

const rules = {
    required: value => {
        if (!value) return 'Ce champ est requis';
        else {
            return !!value || 'Ce champ est requis';
        }
    },
    requiredBoolean: value => {
        return (value != undefined && value != null) || 'Ce champ est requis';
    },
    validEmail: value => {
        if (!value) return true;
        else {
            if (value.length > 0) {
                const pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,7}$/g;
                return pattern.test(value) || 'Veuillez saisir une adresse mail valide';
            } else {
                return 'Veuillez saisir une adresse mail valide';
            }
        }
    },
    isPasswordValidFormat: value => {
        if (!value) return 'Min. 8 caractères avec au moins une lettre majuscule, un chiffre et un caractère spécial.';
        else {
            if (value.length > 0) {
                const pattern =
					/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])(?=.{8,})/;
                return (
                    pattern.test(value) ||
					'Min. 8 caractères avec au moins une lettre majuscule, un chiffre et un caractère spécial.'
                );
            }
        }
    },
    minChars: value => {
        if (!value) return '3 caractères minimum';
        else {
            return value.length > 2 || '3 caractères minimum';
        }
    },
    max50: value => {
        return value ? value.length <= 50 || '50 caractères max' : true;
    },
    max255: value => {
        return value ? value.length <= 255 || '255 caractères max' : true;
    },
    max500: value => {
        return value ? value.length <= 500 || '500 caractères max' : true;
    },
    max1000: value => {
        return value ? value.length <= 1000 || '1000 caractères max' : true;
    },
    notBlank: value => {
        const pattern = /\S/;
        return (
            pattern.test(value) ||
			'Ce champ doit comporter des caractères alphanumériques'
        );
    },
    atLeastOneRole: value => {
        if (!value) return 'Veuillez attribuer au moins un rôle';
        else {
            return value.length > 0 || 'Veuillez attribuer au moins un rôle';
        }
    },
    atLeastOneSite: value => {
        if (!value) return 'Veuillez attribuer au moins un site';
        else {
            return value.length > 0 || 'Veuillez attribuer au moins un site';
        }
    },
    atLeastOneTechnician: value => {
        if (!value) return 'Au moins 1 technicien requis';
        else {
            return value.length > 0 || 'Au moins 1 technicien requis';
        }
    },

    atLeastOneMaterial: value => {
        if (!value?.id) return 'Renseignez une catégorie de matériaux';
        else {
            return true;
        }
    },

    correctYearFormat: value => {
        if (!value || value === '') return true;

        const regex = new RegExp('(^[0-9]{4}$)');
        return regex.test(value) || 'format de l\'année invalide';
    },

    onlyNumbers: value => {
        if ('' === value || value == undefined) return true;
        const regex = new RegExp('(^[0-9]*$)');
        return regex.test(value) || 'Seulement des chiffres';
    },

    validPhoneNumber: value => {
        if ('' === value || value == undefined) return true;
        const regex = new RegExp(
            '((^(?:(?:\\+|00)33[\\s.-]{0,3}(?:\\(0\\)[\\s.-]{0,3})?|0)[1-9](?:(?:[\\s.-]?\\d{2}){4}|\\d{2}(?:[\\s.-]?\\d{3}){2})$))',
        );
        return regex.test(value) || 'Le numéro de téléphone ne correspond pas';
    },

    FourMax: value => {
        if ('' === value || value == undefined) return true;
        value = value.toString();
        return value.length <= 4 || '4 chiffres maximum';
    },

    requiredDiameter: value => {
        return !!value || 'Renseignez le diamètre de la carotte';
    },

    layerState: value => {
        return !!value || 'Indiquez l\'état de la couche';
    },

    interfaceState: value => {
        return !!value || 'Indiquez l\'état de l\'interface';
    },

    min5Chars: value => {
        if (!value) return 'Le nom doit comporter au moins 5 caractères';
        else {
            return (
                (value && value.length >= 5) ||
				'Le nom doit comporter au moins 5 caractères'
            );
        }
    },

    min6Chars: value => {
        if (!value) return 'Le nom doit comporter au moins 6 caractères';
        else {
            return (
                (value && value.length >= 6) ||
				'Le nom doit comporter au moins 6 caractères'
            );
        }
    },

    min8Chars: value => {
        if (!value) return 'Le nom doit comporter au moins 8 caractères';
        else {
            return (
                (value && value.length >= 8) ||
				'Le nom doit comporter au moins 8 caractères'
            );
        }
    },

    min30Chars: value => {
        if (!value) return 'La description doit comporter au moins 30 caractères';
        else {
            return (
                (value && value.length >= 30) ||
				'La description doit comporter au moins 30 caractères'
            );
        }
    },

    validZipCode: value => {
        if (!value) return 'Code postal invalide';
        else {
            return value.length === 5 || 'Code postal invalide';
        }
    },

    atLeastOne: value =>
        !value ? 'Au moins 1 requis' : value.length > 0 || 'Au moins 1 requis',

    earlierDate: (fromValue, toValue) => {
        if (!toValue || fromValue === toValue) return true;
        else {
            return (
                fromValue < toValue ||
				'La date de début doit être inférieure ou égale à la date de fin'
            );
        }
    },

    laterDate: (fromValue, toValue) => {
        if (!fromValue || fromValue === toValue) return true;
        else {
            return (
                fromValue < toValue ||
				'La date de fin doit être supérieure ou égale à la date de début'
            );
        }
    },

    atLeastOneEngineerOrStudyManager: (engineerId, studyManagerId) => {
        if (!engineerId && !studyManagerId) {
            return 'Vous devez choisir au moins un chargé d\'affaires référent ou un ingénieur';
        }
        return true;
    },

    abscissaMustBeFilled: (abscissa, landmark) => {
        if (
            (null === abscissa && null !== landmark) ||
			('' === abscissa && '' !== landmark)
        ) {
            return 'Vous devez aussi remplir ce champ';
        }
        return true;
    },

    landmarkMustBeFilled: (landmark, abscissa) => {
        if (
            (null === landmark && null !== abscissa) ||
			('' === landmark && '' !== abscissa)
        ) {
            return 'Vous devez aussi remplir ce champ';
        }
        return true;
    },

    requiredRoad: value => {
        return !!value || 'Renseignez la route';
    },

    validSiret: value => {
        if ('' === value || value == undefined) return true;
        const regex = new RegExp('^\\d{14}$');
        return regex.test(value) || '14 chiffres attendus';
    },

    validId: value => {
        if ('' === value || value == undefined) return true;
        const regex = new RegExp('^(?!0+$)\\d{1,3}$');
        return regex.test(value) || 'Pas plus de 3 chiffres attendus';
    },

    isDifferentCustomerReference: (oldVal, newVal) => {
        if (oldVal === newVal) {
            return 'La référence cliente est déjà utilisée';
        }
        return true;
    },

    requiredIfTrue: (shouldBeTrue, value) => {
        if (false === shouldBeTrue) return true;
        if (true === shouldBeTrue && value === null) return 'Vous devez obligatoirement choisir une image';
        return true;
    },
};

export default {
    install () {
        Vue.prototype.$rules = rules;
    },

    rules,
};
